import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image';
import {
    TiSocialTwitter,
    TiSocialLinkedin,
    TiSocialInstagram,
} from 'react-icons/ti';

import Layout from "../components/layout"
import SEO from "../components/seo"

const socialIcons = [
    {
        href: 'https://twitter.com/katie__berry',
        icon: <TiSocialTwitter />,
        text: 'Twitter',
    },
    {
        href: 'https://www.linkedin.com/in/katiemurray88/',
        icon: <TiSocialLinkedin />,
        text: 'LinkedIn',
    },
    {
        href: 'https://www.instagram.com/katie_m_berry',
        icon: <TiSocialInstagram />,
        text: 'Instagram',
    },
]

const IndexPage = ({data}) => (
    <Layout>
        <SEO title="Home" keywords={[
            'katie berry',
            'phd',
            'ladybirds',
            'ladybird challenge',
            'statistician',
        ]} />

        <article>
            <div className="vh-50 cover bg-center" style={{ backgroundImage: `url(${data.bannerImage.childImageSharp.fixed.src})`}}></div>

            <aside className="tc mt4 mb4">
                <ul className="list f1">
                    {socialIcons.map((social) => (
                        <li className="dib mh1" key={social.href}>
                            <a className="link o-60 glow gray" href={social.href} title={social.text}>
                                {social.icon}
                            </a>
                        </li>
                    ))}
                </ul>
            </aside>

            <div className="ph4 ph5-m ph6-l">
                <div className="pt3 pb5 f4 f2-ns measure center tc">
                    <h1 className="fw6 f1 fl w-100 black-70 mt0 mb3 avenir">I'm Dr Katie Berry</h1>
                    <p className="db lh-copy black-70 serif fw1 mv0 f4 f3-m f2-l measure baskerville">
                        Citizen science enthusiast; holds a fondness for Ladybirds; mother of one; maintainer
                        of <a className="link" title="Ladybird Challenge" href="http://ladybirdchallenge.co.uk">Ladybird Challenge</a> and statistician at the Scottish Government.
                    </p>
                </div>
            </div>

            <div className="cf mw8 center">
                <div className='fl w-33 w-50-m w-33-l pr2 pr2-l'>
                    <div className="pv6 cover bg-center" style={{ backgroundImage: `url(${data.katieNamibiaImage.childImageSharp.fixed.src})` }} />
                </div>
                <div className='fl w-33 w-50-m w-33-l ph3 pr0-m ph3-l'>
                    <div className="pv6 cover bg-center" style={{ backgroundImage: `url(${data.katieImage.childImageSharp.fixed.src})` }} />
                </div>
                <div className='fl w-33 w-100-m w-33-l pl2 pl0-m pl2-l mt4-m'>
                    <div className="pv6 cover bg-center" style={{ backgroundImage: `url(${data.katieForestCameraImage.childImageSharp.fixed.src})` }} />
                </div>
            </div>

            <div className="measure ph3 ph0-l f3 center mv5 black-70">
                <h1 className="fw6 f3 avenir">PhD at The University of Stirling</h1>
                <p className="lh-copy measure f4 f3-ns black-70 baskerville">
                    My PhD (The role of parasites in the invasion biology of <i className="i">Harmonia axyridis</i>)
                    examined several ladybird parasites, including the fungus <i className="i">Hesperomyces virecens</i>,
                    and their association with the harlequin ladybird in the UK. I completed my PhD in August 2017
                    at the University of Stirling and graduated in May 2018.
                </p>
            </div>
            <div className="measure center tc">
                <Img className="db" fixed={data.phdImage.childImageSharp.fixed} />
            </div>

            <div className="measure ph3 ph0-l f3 center mv5 black-70">
                <h1 className="fw6 f3 avenir">Take the Ladybird Challenge</h1>
                <p className="lh-copy measure f4 f3-ns black-70 baskerville">
                    The Ladybird Challenge was created during my PhD as a citizen science recording project.
                    The Ladybird Challenge asks the public to record 7-spot ladybirds and any sightings of the
                    parasitic wasp <i className="i">Dinocampus coccinellae</i>. For more information about this
                    project, and videos explaining the background in more detail,
                    go to <a className="link" title="Ladybird Challenge" href="https://ladybirdchallenge.co.uk">The Ladybird Challenge website</a>.
                </p>
            </div>
            <div className="measure center tc">
                <Img className="db" fixed={data.ladybirdChallengeImage.childImageSharp.fixed} />
            </div>

            <div className="measure ph3 ph0-l f3 center mv5 black-70">
                <h1 className="fw6 f3 avenir">Family Time</h1>
                <p className="lh-copy measure f4 f3-ns black-70 baskerville">
                    My daughter was born in November 2018 and since then family life has been rather hectic,
                    but I wouldn't have it any other way!
                </p>
            </div>
            <div className="measure center tc">
                <Img className="db" fixed={data.familyImage.childImageSharp.fixed} />
            </div>

            <div className="measure ph3 ph0-l f3 center mv5 black-70">
                <h1 className="fw6 f3 avenir">The Statistician</h1>
                <p className="lh-copy measure f4 f3-ns black-70 baskerville">
                    I currently work at the Scottish Government, analysing and presenting data to senior management
                    relating to the current workforce in government.
                </p>
            </div>

            <div className="measure center tc">
                <Img className="db" fixed={data.workImage.childImageSharp.fixed} />
            </div>

            <aside className="tc mt6 nb5 pb3">
                <ul className="list f2">
                    {socialIcons.map((social) => (
                        <li className="dib mh1" key={social.href}>
                            <a className="link o-60 glow gray" href={social.href} title={social.text}>
                                {social.icon}
                            </a>
                        </li>
                    ))}
                </ul>
            </aside>
        </article>
    </Layout>
);

export const query = graphql`
    query HomePageQuery {
        bannerImage: file(name:{ eq: "banner-image" }) {
            childImageSharp {
                fixed(width: 2000 cropFocus: CENTER) {
                    src
                }
            }
        }
        katieImage: file(name:{ eq: "katie" }) {
            childImageSharp {
                fixed(width: 500 cropFocus: CENTER) {
                    src
                }
            }
        }
        katieNamibiaImage: file(name:{ eq: "katie-namibia" }) {
            childImageSharp {
                fixed(width: 500 cropFocus: CENTER) {
                    src
                }
            }
        }
        katieForestCameraImage: file(name:{ eq: "katie-forest-camera" }) {
            childImageSharp {
                fixed(width: 1000 cropFocus: CENTER) {
                    src
                }
            }
        }
        phdImage: file(name:{ eq: "katie-graduation" }) {
            childImageSharp {
                fixed(width: 350 height: 350 cropFocus: CENTER) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        ladybirdChallengeImage: file(name:{ eq: "ladybird-fungus" }) {
            childImageSharp {
                fixed(width: 350 height: 350 cropFocus: CENTER) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        familyImage: file(name:{ eq: "isla-flowers" }) {
            childImageSharp {
                fixed(width: 350 height: 350 cropFocus: CENTER) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        workImage: file(name:{ eq: "katie-snow" }) {
            childImageSharp {
                fixed(width: 350 height: 350 cropFocus: CENTER) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
  }
`

export default IndexPage
